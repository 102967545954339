
import {Options} from 'vue-class-component'
import Button from '../../../atoms/button/index.vue'
import {Vuei18n} from '../../../../i18next/Vuei18n'
import urls from './../../../../urls'

@Options({
  components: {
    Button
  }
})
export default class DashboardButton extends Vuei18n {
  get dashboardLink(): string {
    return urls().dashboard
  }
}


import {Options, Vue} from 'vue-class-component'
import {Prop} from 'vue-property-decorator'
import AccountLogo from '../../../../icons/AccountLogo.vue'
import {User} from '../types'

@Options({
  components: {
    AccountLogo
  }
})
export default class ProfileButton extends Vue {
  @Prop({default: () => {}}) handleClick!: () => void
  @Prop({default: null}) user!: User | null

  get userName() {
    return this.user ? `${this.user?.firstName} ${this.user?.lastName}` : ''
  }
}

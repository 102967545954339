import {LinkIcons} from './NavigationDrawer/ListItems/types'

export type ActionSectionPayload = {
  showSignInButton: boolean
  showSignUpButton: boolean
  showDashboardButton: boolean
  showProfileButton: boolean
  showBurgerMenu: boolean
}

export type NavigationDrawerPayload = {
  showSignInButton: boolean
  showSignUpButton: boolean
  showDashboardButton: boolean
  showLogoutButton: boolean
  showAccountLinks: boolean
}

type BaseRoute = {
  title: string
  active: boolean
  icon?: LinkIcons | null
}

export type CallbackRoute = BaseRoute & {
  callback: () => void
}

export type LinkRoute = BaseRoute & {
  url: string
}

export type Route = CallbackRoute | LinkRoute

export const isCallbackRoute = (route: CallbackRoute | LinkRoute): route is CallbackRoute => {
  return (route as CallbackRoute).callback !== undefined
}

export enum DisplayType {
  FOCUSED = 'focused',
  SECURED = 'secured',
  PUBLIC = 'public'
}

export type User = {
  userId: number
  firstName: string
  lastName: string
}

import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6391797c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brand-item" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", { href: _ctx.logoUrl }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.logo)))
    ], 8, _hoisted_2)
  ]))
}

import {Options, Prop} from 'vue-property-decorator'
import Close from '../../../../icons/Close.vue'
import {Vuei18n} from '../../../../i18next/Vuei18n'

@Options({
  components: {
    Close
  }
})
export default class Drawer extends Vuei18n {
  @Prop({default: false}) open!: boolean
  @Prop() toggleDrawer?: () => void
}


import {Options, Prop, Vue} from 'vue-property-decorator'
import {User} from '../../types'

@Options({})
export default class UserItem extends Vue {
  @Prop({default: null}) user!: User | null

  get userName() {
    return this.user ? `${this.user?.firstName} ${this.user?.lastName}` : ''
  }
  get userId() {
    return this.user?.userId ? `${this.user?.userId}` : 'no-ID'
  }
}

import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8dc8eee6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("a", {
      class: _normalizeClass(["brand-icon-link-item", {'brand-small': _ctx.smallVariant, 'brand-active': _ctx.active}]),
      href: _ctx.href ? _ctx.href : undefined,
      target: _ctx.href ? _ctx.target : undefined,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.callback && _ctx.callback()))
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconComponent), {
        width: "20",
        height: "20"
      })),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.textColorClass)
      }, _toDisplayString(_ctx.text), 3)
    ], 10, _hoisted_1)
  ]))
}
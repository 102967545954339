import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Auth = _resolveComponent("Auth")!
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Auth, {
      forceAuth: _ctx.forceAuth,
      authCallback: _ctx.handleCallback
    }, null, 8, ["forceAuth", "authCallback"]),
    _createVNode(_component_Header, {
      title: _ctx.title,
      type: _ctx.type,
      logoUrl: _ctx.logoUrl,
      userFetched: _ctx.userFetched,
      showSupportButton: _ctx.showSupportButton,
      showBaaderLink: _ctx.hasBaaderAccount,
      routes: _ctx.routes,
      user: _ctx.user
    }, null, 8, ["title", "type", "logoUrl", "userFetched", "showSupportButton", "showBaaderLink", "routes", "user"])
  ]))
}

import {Options, Vue} from 'vue-property-decorator'
import DialPopup from './DialPopup.vue'
import Button from '../../../../atoms/button/index.vue'

@Options({
  components: {
    DialPopup,
    Button
  }
})
export default class DialCustomerService extends Vue {
  shouldPopupOpen: boolean = false

  toggleOpen() {
    this.shouldPopupOpen = !this.shouldPopupOpen
  }
}


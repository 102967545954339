import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22a1e173"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brand-account-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountLogo = _resolveComponent("AccountLogo")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "brand-account-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
    }, [
      _createVNode(_component_AccountLogo, { size: "36" }),
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.userName), 1)
    ])
  ]))
}
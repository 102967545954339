
import {Options, Prop, Vue} from 'vue-property-decorator'
import Auth from './auth/index.vue'
import Header from '../header/index.vue'
import {fetchUser} from './request'
import {User, Route, DisplayType} from '../header/types'
import {Token} from './auth/interfaces/Token'
import urls from '../../../urls'
import {isFunction} from '../../../helper/WebComponentsHelper'

@Options({
  components: {Auth, Header}
})
export default class AuthHeader extends Vue {
  @Prop({default: null}) authCallback!: ((user: User | null, token: Token | null, _hasBaader: boolean | null) => void) | null
  @Prop({default: false }) forceAuth!: boolean
  @Prop({default: null}) title!: string | null
  @Prop({default: []}) routes!: Route[]
  @Prop({default: null}) logoUrl!: string | null
  @Prop({default: false}) showSupportButton!: boolean
  @Prop({default: DisplayType.PUBLIC}) type!: DisplayType
  user: User | null = null
  userFetched: boolean = false
  authCallbackHandled: boolean = false
  showBaaderLink: boolean = false

  handleCallback(token: Token | null) {
    if (token) {
      fetchUser(token, (user: User | null) => {
        this.user = user

        if (this.user) {
          this.userFetched = true
          if (this.type !== DisplayType.FOCUSED) {
            this.checkBaaderDepot(token)
          }
        }

        if (!this.authCallbackHandled && user) {
          this.authCallbackHandled = true
          return this.authCallback && isFunction(this.authCallback) && this.authCallback(user, token, this.showBaaderLink)
        }
      })
    } else {
      this.userFetched = true
      return this.authCallback && isFunction(this.authCallback) && this.authCallback(null, null, null)
    }
  }

  checkBaaderDepot(token: Token) {
    fetch(urls().baaderBankAPIUrl, {
      headers: { authorization: 'Bearer ' + token?.idToken }
    })
      .then((res) =>{ this.showBaaderLink = res.status === 200 })
      .catch((err) => console.error(err))
  }

  get hasBaaderAccount() {
    return this.showBaaderLink
  }
}

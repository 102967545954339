import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-222eef8b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brand-logo-container" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.redirectUrl,
      class: "brand-logo-big"
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.logo), {
        class: _normalizeClass({'brand-logo': _ctx.small})
      }, null, 8, ["class"]))
    ], 8, _hoisted_2),
    (_ctx.small)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: _ctx.redirectUrl,
          class: "brand-logo-small"
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.logoSmall)))
        ], 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}

import {Options, Prop, Vue} from 'vue-property-decorator'
import urls from '../../../../urls'
import {resumeSession} from './Auth'
import {Token} from './interfaces/Token'
import {isFunction} from '../../../../helper/WebComponentsHelper'

@Options({})
export default class Auth extends Vue {
  @Prop({default: false}) forceAuth!: boolean
  @Prop({default: () => {}}) authCallback!: (token: Token | null) => void

  mounted() {
    if (!isFunction(this.authCallback)) this.authCallback = () => {}

    window.addEventListener('message', async (event: MessageEvent) => {
      const postedMessage = (typeof event.data === 'string' && this.isJson(event.data)) ? JSON.parse(event.data) : event.data
      if (!!postedMessage.type && postedMessage.type.includes('sso')) {
        if (postedMessage.payload.error && this.forceAuth) {
          location.href = `${urls().userServiceBaseUrl}/#/signin?redirectURL=${encodeURIComponent(location.href)}`
        } else if (postedMessage.payload.error && !this.forceAuth) {
          this.authCallback(null)
        } else if (postedMessage.payload.refreshToken) {
          await resumeSession(
            postedMessage.payload.refreshToken,
            this.authCallback
          )
        }
      }
    })
  }

  getIframeSrc(): string | null {
    return `${urls().userServiceAPIUrl}/user/session`
  }

  isJson(str: string): any  {
    try {
      return JSON.parse(str)
    } catch {
      return false
    }
  }
}

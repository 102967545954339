import {Logos} from '../types'
import LogoBig from './BrandComponents/Logos/LogoBig.vue'
import LogoSmall from './BrandComponents/Logos/LogoSmall.vue'

export default (): Logos => {
  return {
    logo: LogoBig,
    logoSmall: LogoSmall
  }
}

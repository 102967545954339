
import {Options, Prop, Vue} from 'vue-property-decorator'
import {ActionSectionPayload, DisplayType, NavigationDrawerPayload, Route, User} from './types'
import ContentSection from './ContentSection.vue'
import Action from './Action/index.vue'
import BrandLogo from './BrandLogo.vue'
import NavigationDrawer from './NavigationDrawer/index.vue'
import {defaultActionSectionPayload, defaultNavigationDrawerPayload } from './defaultValues'

@Options({
  components: {
    ContentSection,
    Action,
    NavigationDrawer,
    BrandLogo
  }
})
export default class Header extends Vue {
  @Prop({default: null}) logoUrl!: string | null
  @Prop({default: null}) title!: string | null
  @Prop({default: []}) routes!: Route[]
  @Prop({default: null}) user!: User | null
  @Prop({default: null}) userFetched!: null | boolean
  @Prop({default: false}) showSupportButton!: boolean
  @Prop({default: false}) showBaaderLink!: boolean
  @Prop({default: DisplayType.PUBLIC}) type!: DisplayType
  navigationDrawerIsOpen: boolean = false

  get shouldShowNavigationItems() {
    return this.userFetched === null || this.userFetched
  }

  get renderNavigationDrawer(): boolean {
    return this.type !== DisplayType.FOCUSED
  }

  get actionPayload(): ActionSectionPayload {
    switch(this.type) {
    case DisplayType.FOCUSED:
      return {
        ...defaultActionSectionPayload,
        showBurgerMenu: false
      }
    case DisplayType.SECURED:
      return {
        ...defaultActionSectionPayload,
        showSignInButton: !this.hasUser,
        showSignUpButton: !this.hasUser,
        showProfileButton: this.hasUser
      }
    case DisplayType.PUBLIC:
      return {
        ...defaultActionSectionPayload,
        showDashboardButton: this.hasUser,
        showSignInButton: !this.hasUser,
        showSignUpButton: !this.hasUser
      }
    default:
      return {...defaultActionSectionPayload}
    }
  }

  get navigationDrawerPayload(): NavigationDrawerPayload {
    switch (this.type) {
    case DisplayType.FOCUSED:
      return {...defaultNavigationDrawerPayload}
    case DisplayType.SECURED:
      return {
        ...defaultNavigationDrawerPayload,
        showSignInButton: !this.hasUser,
        showSignUpButton: !this.hasUser,
        showLogoutButton: this.hasUser,
        showAccountLinks: this.hasUser
      }
    case DisplayType.PUBLIC:
      return {
        ...defaultNavigationDrawerPayload,
        showDashboardButton: this.hasUser,
        showSignInButton: !this.hasUser,
        showSignUpButton: !this.hasUser
      }
    default:
      return {...defaultNavigationDrawerPayload}
    }
  }

  get currentRoutes(): Route[] {
    switch(this.type) {
    case DisplayType.FOCUSED:
      return []
    case DisplayType.SECURED:
      return this.hasUser ? this.routes : []
    case DisplayType.PUBLIC:
      return this.routes
    default:
      return this.routes
    }
  }

  get hasUser() {
    return !!this.user
  }
}


import {Options, Prop} from 'vue-property-decorator'
import {Vuei18n} from '../../../../../i18next/Vuei18n'
import Button from '../../../../atoms/button/index.vue'

@Options({
  components: {
    Button
  }
})
export default class DialPopup extends Vuei18n {
  @Prop({default: false}) open!: boolean

  get isOnline(): boolean {
    const now = new Date()
    const hours = now.getHours()

    return now.getDay() <= 5 && (hours >= 9 && hours <= 20)
  }

  get mail(): string {
    return this.$t('header.customerService.email')
  }

  get phone(): string {
    return this.$t('header.customerService.phone')
  }

  get parsedPhone(): string {
    return this.$t('header.customerService.phone')
      .replace('-', '')
      .replace(/\s+/g, '')
  }

  copyMail(event: Event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.mail)
  }

  copyPhone(event: Event) {
    if (event) {
      event.preventDefault()
      navigator.clipboard.writeText(this.phone)
    }
  }
}


import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["brand-dial-popup", {'brand-open': _ctx.open}])
  }, [
    (_ctx.isOnline)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("h6", null, _toDisplayString(_ctx.$t('header.customerService.online.title')), 1),
          _createElementVNode("small", null, _toDisplayString(_ctx.$t('header.customerService.officeHours')), 1),
          _createVNode(_component_Button, {
            class: "brand-mobile",
            title: _ctx.phone,
            href: `tel:${_ctx.parsedPhone}`,
            alignment: "text-start",
            type: "tertiary"
          }, null, 8, ["title", "href"]),
          _createVNode(_component_Button, {
            class: "brand-desktop",
            title: _ctx.phone,
            type: "tertiary",
            alignment: "text-start",
            onClick: _ctx.copyPhone
          }, null, 8, ["title", "onClick"])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("h6", null, _toDisplayString(_ctx.$t('header.customerService.offline.title')), 1),
          _createElementVNode("small", null, _toDisplayString(_ctx.$t('header.customerService.officeHours')), 1),
          _createVNode(_component_Button, {
            class: "brand-mobile",
            title: _ctx.$t('header.customerService.sendmail'),
            href: `mailto:${_ctx.mail}`,
            alignment: "text-start",
            type: "tertiary",
            onClick: _ctx.copyMail
          }, null, 8, ["title", "href", "onClick"]),
          _createVNode(_component_Button, {
            class: "brand-desktop",
            title: _ctx.$t('header.customerService.sendmail'),
            href: `mailto:${_ctx.mail}`,
            alignment: "text-start",
            type: "tertiary"
          }, null, 8, ["title", "href"])
        ], 64))
  ], 2))
}
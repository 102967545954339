import {ActionSectionPayload, NavigationDrawerPayload } from './types'

export const defaultNavigationDrawerPayload: NavigationDrawerPayload = {
  showSignInButton: false,
  showSignUpButton: false,
  showDashboardButton: false,
  showLogoutButton: false,
  showAccountLinks: false
}

export const defaultActionSectionPayload: ActionSectionPayload = {
  showSignInButton: false,
  showSignUpButton: false,
  showDashboardButton: false,
  showProfileButton: false,
  showBurgerMenu: true
}


import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8fec2558"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brand-support-item" }
const _hoisted_2 = { class: "brand-support-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionMark = _resolveComponent("QuestionMark")!
  const _component_IconLinkItem = _resolveComponent("IconLinkItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_QuestionMark),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('header.support.title')), 1),
      _createVNode(_component_IconLinkItem, {
        class: "brand-phone-item",
        icon: "Phone",
        href: "tel:+494079729570",
        text: "+49 40 79729570",
        color: "secondary",
        smallVariant: ""
      }),
      _createVNode(_component_IconLinkItem, {
        icon: "Mail",
        href: "mailto:support@propvest.com",
        text: "support@propvest.com",
        color: "secondary",
        smallVariant: ""
      })
    ])
  ]))
}
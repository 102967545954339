
import { Prop, Options, Vue } from 'vue-property-decorator'
import {Route, isCallbackRoute} from './types'

@Options({
})
export default class ContentSection extends Vue {
  @Prop({default: []}) routes!: Route[]
  @Prop({default: null}) title!: string | null

  get hasRoutes(): boolean {
    return this.routes && this.routes.length > 0
  }

  handleCallback(route: Route): void {
    isCallbackRoute(route) && route.callback()
  }

  getHref(route: Route): string | undefined {
    return isCallbackRoute(route)
      ? undefined
      : route.url
  }
}

export enum LinkIcons {
    ACCOUNT_LOGO = 'AccountLogo',
    BAADERBANK = 'BaaderBankLogo',
    DOCUMENTS = 'Documents',
    DEFAULT = 'DefaultIcon',
    PORTFOLIO = 'Portfolio',
    DASHBOARD = 'Dashboard',
    INVEST = 'Invest',
    LOGOUT = 'Logout',
    PHONE = 'Phone',
    MAIL = 'Mail',
}

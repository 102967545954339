
import {Options, Prop, Vue} from 'vue-property-decorator'
import {Component} from '@vue/runtime-core'
import logos from '../../../logos'
import urls from '../../../urls'

@Options({})
export default class BrandLogo extends Vue {
  @Prop({default: null}) logoUrl!: string | null
  @Prop({default: false}) small!: boolean

  get redirectUrl(): string {
    return this.logoUrl ? this.logoUrl : urls().home
  }

  get logo(): Component {
    return logos().logo
  }
  get logoSmall(): Component {
    return logos().logoSmall
  }
}

import AuthResponse from './interfaces/AuthResponse'
import urls from '../../../../urls'
import {Token} from './interfaces/Token'

export const resumeSession = async (refreshToken: string, authCallback?: (token: Token | null) => void) => {
  const USER_POOL_ID = urls().userPoolID || ''
  const AWS_REGION = USER_POOL_ID.split('_')[0]
  const USER_POOL_CLIENT_ID = urls().userPoolClientID

  const endpoint = `https://cognito-idp.${AWS_REGION}.amazonaws.com/`

  let token: Token | null = null

  await fetch(endpoint, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      'Content-Type': 'application/x-amz-json-1.1'
    },
    cache: 'no-cache',
    body: JSON.stringify({
      ClientId: USER_POOL_CLIENT_ID,
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken
      }
    })
  })
    .then((res: Response) => res.json())
    .then((res: AuthResponse) => {
      const idToken = res.AuthenticationResult?.IdToken
      const accessToken = res.AuthenticationResult?.AccessToken
      const expiredIn = res.AuthenticationResult?.ExpiresIn

      token = {
        idToken,
        accessToken,
        refreshToken,
        expiredIn
      }
    }).finally(() => {
      if (authCallback) {
        authCallback(token)
      }
    })
}

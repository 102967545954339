import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa8081b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "brand-link-wrapper"
}
const _hoisted_2 = ["href", "onClick"]
const _hoisted_3 = {
  key: 1,
  class: "brand-title-container"
}
const _hoisted_4 = { class: "brand-title" }
const _hoisted_5 = { class: "brand-title-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["brand-header-content", {'brand-header-conditionals': _ctx.hasRoutes}])
  }, [
    (_ctx.hasRoutes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["brand-link-container", {'brand-active': route.active}])
            }, [
              _createElementVNode("a", {
                class: "brand-link",
                href: _ctx.getHref(route),
                onClick: () => _ctx.handleCallback(route)
              }, _toDisplayString(route.title), 9, _hoisted_2)
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.hasRoutes && _ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
import {Token} from '../auth/interfaces/Token'
import {User} from '../../header/types'
import urls from '../../../../urls'

export const fetchUser = (token: Token, callback: (user: User | null) => void) => {
  const xmlHttp = new XMLHttpRequest()
  xmlHttp.onreadystatechange = () => {
    if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
      const response = JSON.parse(xmlHttp.responseText)
      const user = {
        userId: response.id,
        firstName: response.naturalUser.firstName,
        lastName: response.naturalUser.lastName
      }
      callback(user)
    } else {
      callback(null)
    }
  }
  xmlHttp.open('GET', urls().userMeta, true)
  xmlHttp.setRequestHeader('Authorization', 'Bearer ' + token.idToken)
  xmlHttp.send()
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    href: _ctx.signUpLink,
    title: _ctx.$t('header.action.register'),
    small: ""
  }, null, 8, ["href", "title"]))
}
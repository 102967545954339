
import DialCustomerService from './dialCusomerService/DialCustomerService.vue'
import BurgerMenu from '../../../../icons/BurgerMenu.vue'
import { ActionSectionPayload, User } from '../types'
import { Prop, Options } from 'vue-property-decorator'
import {Vuei18n} from '../../../../i18next/Vuei18n'
import SignInButton from '../Buttons/SignInButton.vue'
import SignUpButton from '../Buttons/SignUpButton.vue'
import DashboardButton from '../Buttons/DashboardButton.vue'
import ProfileButton from '../Buttons/ProfileButton.vue'
import { defaultActionSectionPayload } from '../defaultValues'

@Options({
  components: {
    DashboardButton,
    SignUpButton,
    SignInButton,
    ProfileButton,
    DialCustomerService,
    BurgerMenu
  }
})
export default class Action extends Vuei18n {
  @Prop({default: false}) showSupportButton!: boolean
  @Prop({default: defaultActionSectionPayload}) actionPayload!: ActionSectionPayload
  @Prop({default: null}) toggleDrawer!: (() => void) | null
  @Prop({default: null}) user!: User | null
}

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5119d69b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right-drawer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.open ? 'open' : 'closed')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createVNode(_component_Close, {
        fill: "#212121",
        size: "14",
        class: "drawer-close",
        onClick: _ctx.toggleDrawer
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", {
      class: "drawer-mask",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDrawer && _ctx.toggleDrawer(...args)))
    })
  ], 2))
}
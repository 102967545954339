import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d16253c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brand-header" }
const _hoisted_2 = { class: "brand-header-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_ContentSection = _resolveComponent("ContentSection")!
  const _component_Action = _resolveComponent("Action")!
  const _component_NavigationDrawer = _resolveComponent("NavigationDrawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BrandLogo, {
        logoUrl: _ctx.logoUrl,
        small: !!_ctx.title
      }, null, 8, ["logoUrl", "small"]),
      (_ctx.shouldShowNavigationItems)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_ContentSection, {
              routes: _ctx.currentRoutes,
              title: _ctx.title
            }, null, 8, ["routes", "title"]),
            _createVNode(_component_Action, {
              user: _ctx.user,
              routes: _ctx.routes,
              showSupportButton: _ctx.showSupportButton,
              actionPayload: _ctx.actionPayload,
              toggleDrawer: () => _ctx.navigationDrawerIsOpen = !_ctx.navigationDrawerIsOpen
            }, null, 8, ["user", "routes", "showSupportButton", "actionPayload", "toggleDrawer"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    (_ctx.renderNavigationDrawer)
      ? (_openBlock(), _createBlock(_component_NavigationDrawer, {
          key: 0,
          logoUrl: _ctx.logoUrl,
          user: _ctx.user,
          routes: _ctx.currentRoutes,
          showSupportButton: _ctx.showSupportButton,
          showBaaderLink: _ctx.showBaaderLink,
          navigationDrawerPayload: _ctx.navigationDrawerPayload,
          isOpen: _ctx.navigationDrawerIsOpen,
          toggleDrawer: () => _ctx.navigationDrawerIsOpen = !_ctx.navigationDrawerIsOpen
        }, null, 8, ["logoUrl", "user", "routes", "showSupportButton", "showBaaderLink", "navigationDrawerPayload", "isOpen", "toggleDrawer"]))
      : _createCommentVNode("", true)
  ]))
}
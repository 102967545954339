import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14d60ed2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }
const _hoisted_2 = { key: 3 }
const _hoisted_3 = { key: 4 }
const _hoisted_4 = { key: 5 }
const _hoisted_5 = { class: "brand-login-button-container" }
const _hoisted_6 = { key: 6 }
const _hoisted_7 = { class: "brand-dashboard-button" }
const _hoisted_8 = { key: 7 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserItem = _resolveComponent("UserItem")!
  const _component_BrandItem = _resolveComponent("BrandItem")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_IconLinkItem = _resolveComponent("IconLinkItem")!
  const _component_SupportItem = _resolveComponent("SupportItem")!
  const _component_SignUpButton = _resolveComponent("SignUpButton")!
  const _component_SignInButton = _resolveComponent("SignInButton")!
  const _component_DashboardButton = _resolveComponent("DashboardButton")!
  const _component_Drawer = _resolveComponent("Drawer")!

  return (_openBlock(), _createBlock(_component_Drawer, {
    open: _ctx.isOpen,
    toggleDrawer: _ctx.toggleDrawer
  }, {
    default: _withCtx(() => [
      (_ctx.user)
        ? (_openBlock(), _createBlock(_component_UserItem, {
            key: 0,
            user: _ctx.user
          }, null, 8, ["user"]))
        : (_openBlock(), _createBlock(_component_BrandItem, {
            key: 1,
            logoUrl: _ctx.logoUrl
          }, null, 8, ["logoUrl"])),
      (_ctx.hasRoutes)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Divider),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route, index) => {
              return (_openBlock(), _createBlock(_component_IconLinkItem, {
                key: index,
                icon: route.icon,
                text: route.title,
                href: _ctx.getHref(route),
                callback: _ctx.getCallback(route),
                active: route.active
              }, null, 8, ["icon", "text", "href", "callback", "active"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.navigationDrawerPayload.showAccountLinks)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Divider),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountLinkRoutes, (route, index) => {
              return (_openBlock(), _createBlock(_component_IconLinkItem, {
                key: index,
                icon: route.icon,
                text: route.title,
                href: _ctx.getHref(route),
                callback: _ctx.getCallback(route),
                active: route.active
              }, null, 8, ["icon", "text", "href", "callback", "active"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.showSupportButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Divider),
            _createVNode(_component_SupportItem)
          ]))
        : _createCommentVNode("", true),
      (_ctx.navigationDrawerPayload.showSignUpButton && _ctx.navigationDrawerPayload.showSignInButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_Divider),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_SignUpButton),
              _createVNode(_component_SignInButton)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.navigationDrawerPayload.showDashboardButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_Divider),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_DashboardButton)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.navigationDrawerPayload.showLogoutButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_Divider),
            _createVNode(_component_IconLinkItem, {
              icon: "Logout",
              text: _ctx.$t('header.navigation.signOut'),
              onClick: _ctx.logout
            }, null, 8, ["text", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open", "toggleDrawer"]))
}
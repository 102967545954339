
import {Options} from 'vue-property-decorator'
import IconLinkItem from './IconLinkItem.vue'
import {Vuei18n} from '../../../../../i18next/Vuei18n'
import QuestionMark from '../../../../../icons/QuestionMark.vue'

@Options({
  components: {
    QuestionMark,
    IconLinkItem
  }
})
export default class SupportItem extends Vuei18n {
}

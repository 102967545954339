import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 17 19",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M14.1609 7.22344C14.1609 6.51348 14.5327 5.85594 15.1476 5.49858L16.0436 4.97445L16.0436 13.532L8.02182 18.1634L0 13.532V4.26926L7.39265 0L7.39265 1.01967C7.39265 1.73439 7.01134 2.3967 6.39171 2.75406L1.89225 5.35564L1.89225 12.4361L4.30404 13.8275L6.68723 15.2045L7.34976 15.5857L8.02182 15.9764L11.8254 13.7798L14.1609 12.4361V7.22344ZM12.5164 4.40744L9.65182 2.75406C9.03219 2.39669 8.65088 1.73915 8.65088 1.02443V0L15.4001 3.89284L14.5278 4.40268C13.9082 4.7648 13.1408 4.7648 12.5164 4.40744Z",
      fill: _ctx.fill
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}
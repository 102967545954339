import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0795c918"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "brand-action-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialCustomerService = _resolveComponent("DialCustomerService")!
  const _component_SignUpButton = _resolveComponent("SignUpButton")!
  const _component_SignInButton = _resolveComponent("SignInButton")!
  const _component_DashboardButton = _resolveComponent("DashboardButton")!
  const _component_ProfileButton = _resolveComponent("ProfileButton")!
  const _component_BurgerMenu = _resolveComponent("BurgerMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showSupportButton)
      ? (_openBlock(), _createBlock(_component_DialCustomerService, {
          key: 0,
          class: "brand-support-button"
        }))
      : _createCommentVNode("", true),
    (_ctx.actionPayload.showSignUpButton)
      ? (_openBlock(), _createBlock(_component_SignUpButton, {
          key: 1,
          class: "brand-signup-action"
        }))
      : _createCommentVNode("", true),
    (_ctx.actionPayload.showSignInButton)
      ? (_openBlock(), _createBlock(_component_SignInButton, {
          key: 2,
          class: "brand-signin-action"
        }))
      : _createCommentVNode("", true),
    (_ctx.actionPayload.showDashboardButton)
      ? (_openBlock(), _createBlock(_component_DashboardButton, {
          key: 3,
          class: "brand-dashboard-action"
        }))
      : _createCommentVNode("", true),
    (_ctx.actionPayload.showProfileButton)
      ? (_openBlock(), _createBlock(_component_ProfileButton, {
          key: 4,
          "handle-click": _ctx.toggleDrawer,
          class: "brand-profile-action",
          user: _ctx.user
        }, null, 8, ["handle-click", "user"]))
      : _createCommentVNode("", true),
    (_ctx.actionPayload.showBurgerMenu)
      ? (_openBlock(), _createBlock(_component_BurgerMenu, {
          key: 5,
          class: "brand-burger-menu",
          onClick: _ctx.toggleDrawer
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}
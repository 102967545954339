import {Logos} from '../brands/types'
import exporoLogos from '../brands/exporo/logos'
import propvestLogos from '../brands/propvest/logos'

let logos: () => Logos

switch(process.env.VUE_APP_BRAND) {
case 'PROPVEST':
  logos = propvestLogos
  break
case 'EXPORO':
  logos = exporoLogos
  break
default:
  logos = propvestLogos
}

export default logos

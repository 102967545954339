
import {Options, Prop, Vue} from 'vue-property-decorator'
import Dashboard from '../../../../../icons/Dashboard.vue'
import Documents from '../../../../../icons/Documents.vue'
import Invest from '../../../../../icons/Invest.vue'
import ListItemAccount from '../../../../../icons/ListItemAccount.vue'
import Portfolio from '../../../../../icons/Portfolio.vue'
import {LinkIcons} from './types'
import Logout from '../../../../../icons/Logout.vue'
import BaaderBank from '../../../../../icons/BaaderBank.vue'
import Phone from '../../../../../icons/Phone.vue'
import Mail from '../../../../../icons/Mail.vue'
import DefaultIcon from '../../../../../icons/DefaultIcon.vue'

enum TextColorType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

@Options({})
export default class IconLinkItem extends Vue {
  @Prop({default: false, type: Boolean}) smallVariant!: boolean
  @Prop({default: false }) active!: boolean
  @Prop({default: null}) icon!: LinkIcons | string | null
  @Prop({default: ''}) text!: string
  @Prop({ default: TextColorType.PRIMARY }) textColor!: TextColorType
  @Prop({default: null}) href!: string | null
  @Prop({default: '_self'}) target!: string
  @Prop({default: undefined }) callback!: (() => void) | undefined

  get iconComponent() {
    switch (this.icon) {
    case LinkIcons.ACCOUNT_LOGO:
      return ListItemAccount
    case LinkIcons.BAADERBANK:
      return BaaderBank
    case LinkIcons.DOCUMENTS:
      return Documents
    case LinkIcons.INVEST:
      return Invest
    case LinkIcons.DEFAULT:
      return DefaultIcon
    case LinkIcons.PORTFOLIO:
      return Portfolio
    case LinkIcons.DASHBOARD:
      return Dashboard
    case LinkIcons.LOGOUT:
      return Logout
    case LinkIcons.PHONE:
      return Phone
    case LinkIcons.MAIL:
      return Mail
    default:
      return null
    }
  }

  get textColorClass() {
    switch (this.textColor) {
    case TextColorType.PRIMARY:
      return 'brand-black'
    case TextColorType.SECONDARY:
      return 'brand-secondary'
    default:
      return 'brand-black'
    }
  }
}

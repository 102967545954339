
import {Options, Prop} from 'vue-property-decorator'
import {isCallbackRoute, NavigationDrawerPayload, Route, User} from '../types'
import {LinkIcons} from './ListItems/types'
import IconLinkItem from './ListItems/IconLinkItem.vue'
import SupportItem from './ListItems/SupportItem.vue'
import UserItem from './ListItems/UserItem.vue'
import BrandItem from './ListItems/BrandItem.vue'
import Drawer from './Drawer.vue'
import Divider from '../../../atoms/divider/index.vue'
import Button from '../../../atoms/button/index.vue'
import {Vuei18n} from '../../../../i18next/Vuei18n'
import SignUpButton from '../Buttons/SignUpButton.vue'
import SignInButton from '../Buttons/SignInButton.vue'
import DashboardButton from '../Buttons/DashboardButton.vue'
import {defaultNavigationDrawerPayload} from '../defaultValues'
import urls from './../../../../urls'

@Options({
  components: {
    DashboardButton,
    SignUpButton,
    SignInButton,
    Drawer,
    Button,
    IconLinkItem,
    SupportItem,
    UserItem,
    BrandItem,
    Divider
  }
})
export default class NavigationDrawer extends Vuei18n {
  @Prop({default: null}) logoUrl!: string | null
  @Prop({default: null}) user!: User | null
  @Prop({default: false}) isOpen!: boolean
  @Prop({default: null}) toggleDrawer!: (() => void) | null
  @Prop({default: []}) routes!: Route[]
  @Prop({default: false}) showSupportButton!: boolean
  @Prop({default: false}) showBaaderLink!: boolean
  @Prop({default: defaultNavigationDrawerPayload}) navigationDrawerPayload!: NavigationDrawerPayload

  get hasRoutes(): boolean {
    return this.routes && this.routes.length > 0
  }

  get accountLinkRoutes(): Route[] {
    return [
      {
        url: urls().account,
        title: this.$t('header.navigation.accountRoutes.account'),
        icon: LinkIcons.ACCOUNT_LOGO,
        active: window.location.href.includes(urls().account)
      },
      {
        url: urls().documents,
        title: this.$t('header.navigation.accountRoutes.documents'),
        icon: LinkIcons.DOCUMENTS,
        active: window.location.href.includes(urls().documents)
      },
      ...this.showBaaderLink
        ? [{
          url: urls().baaderBank,
          title: this.$t('header.navigation.accountRoutes.baaderBank'),
          icon: LinkIcons.BAADERBANK,
          active: window.location.href.includes(urls().baaderBank)
        }]
        : []
    ]
  }

  getCallback(route: Route): (() => void) | null {
    return isCallbackRoute(route)
      ? route.callback
      : null
  }

  getHref(route: Route): string | undefined {
    return isCallbackRoute(route)
      ? undefined
      : route.url
  }

  logout(): void {
    const form = document.createElement('form')
    form.method = 'post'
    form.action = `${urls().userServiceAPIUrl}/user/session/flush`
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = 'redirectURL'
    hiddenField.value = urls().home

    form.appendChild(hiddenField)

    document.body.appendChild(form)
    form.submit()
  }
}

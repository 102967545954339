
import {Options, Prop, Vue} from 'vue-property-decorator'
import {Component} from '@vue/runtime-core'
import logos from '../../../../../logos'
import urls from '../../../../../urls'

@Options({})
export default class BrandItem extends Vue {
  @Prop({default: urls().home}) logoUrl!: string

  get logo(): Component {
    return logos().logo
  }
}

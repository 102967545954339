
import {Options} from 'vue-class-component'
import Button from '../../../atoms/button/index.vue'
import {Vuei18n} from '../../../../i18next/Vuei18n'
import urls from './../../../../urls'

@Options({
  components: {
    Button
  }
})
export default class SignUpButton extends Vuei18n {
  get signUpLink(): string {
    return urls().signUpLink
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_DialPopup = _resolveComponent("DialPopup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)))
    }, [
      _createVNode(_component_Button, {
        class: "dial-button",
        iconLeft: "phone",
        type: "secondary",
        small: ""
      })
    ]),
    _createVNode(_component_DialPopup, { open: _ctx.shouldPopupOpen }, null, 8, ["open"]),
    (_ctx.shouldPopupOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "mask",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)))
        }))
      : _createCommentVNode("", true)
  ]))
}
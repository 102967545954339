
import {Options, Prop, Vue} from 'vue-property-decorator'

@Options({})
export default class BurgerMenu extends Vue {
  @Prop({ default: 32 }) height!: number
  @Prop({ default: 32 }) width!: number
  @Prop({ default: '#212121' }) fill!: string
}

